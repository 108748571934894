*,
:after,
:before {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

figcaption,
footer,
header,
main,
nav,
section {
    display: block
}

body {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    text-align: left;
    background-color: #fff
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0
}

address,
p {
    margin-bottom: 1rem
}

address {
    font-style: normal;
    line-height: inherit
}

ol,
ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin-bottom: 0
}

b,
strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

a {
    color: #292f3b;
    text-decoration: none;
    background-color: transparent
}

a:hover {
    color: #0a0b0e;
    text-decoration: underline
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

code {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em
}

img {
    border-style: none
}

img,
svg {
    vertical-align: middle
}

svg {
    overflow: hidden
}

table {
    border-collapse: collapse
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

button,
input,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

[type=button],
[type=reset],
[type=submit],
button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled),
button:not(:disabled) {
    cursor: pointer
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

textarea {
    overflow: auto;
    resize: vertical
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

template {
    display: none
}

[hidden] {
    display: none !important
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: .5rem;
    font-weight: 500;
    line-height: 1.2
}

.h1,
h1 {
    font-size: 2.5rem
}

.h2,
h2 {
    font-size: 2rem
}

.h3,
h3 {
    font-size: 1.75rem
}

.h4,
h4 {
    font-size: 1.5rem
}

.h5,
h5 {
    font-size: 1.25rem
}

.h6,
h6 {
    font-size: 1rem
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1)
}

.small,
small {
    font-size: 80%;
    font-weight: 400
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-wrap: break-word
}

a>code {
    color: inherit
}

.container,
.container-fluid {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto
}

@media(min-width: 576px) {
    .container {
        max-width: 540px
    }
}

@media(min-width: 768px) {
    .container {
        max-width: 720px
    }
}

@media(min-width: 992px) {
    .container {
        max-width: 960px
    }
}

@media(min-width: 1200px) {
    .container {
        max-width: 1140px
    }
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px
}

.col,
.col-12,
.col-auto,
.col-lg-3,
.col-lg-4,
.col-lg-6,
.col-lg-10,
.col-lg-auto,
.col-md-4,
.col-md-6,
.col-md-8,
.col-sm-5,
.col-sm-6,
.col-xl-6 {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.row-cols-1>* {
    flex: 0 0 100%;
    max-width: 100%
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

@media(min-width: 576px) {
    .col-sm-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
}

@media(min-width: 768px) {

    .col-md-4,
    .row-cols-md-3>* {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .offset-md-4 {
        margin-left: 33.3333333333%
    }
}

@media(min-width: 992px) {
    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .offset-lg-2 {
        margin-left: 16.6666666667%
    }
}

@media(min-width: 1200px) {
    .col-xl-6 {
        flex: 0 0 50%;
        max-width: 50%
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529
}

.table td,
.table th {
    padding: .75rem;
    vertical-align: top;
    border-top: 1px solid #dee2e6
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6
}

.table tbody+tbody {
    border-top: 2px solid #dee2e6
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(0, 0, 0, .075)
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057
}

.form-control:focus {
    color: #495057;
    background-color: #fff;
    border-color: #5d6b86;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(41, 47, 59, .25)
}

.form-control::-moz-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control::placeholder {
    color: #6c757d;
    opacity: 1
}

.form-control:disabled {
    background-color: #e9ecef;
    opacity: 1
}

input[type=time].form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

select.form-control:focus::-ms-value {
    color: #495057;
    background-color: #fff
}

.col-form-label {
    padding-top: calc(.375rem + 1px);
    padding-bottom: calc(.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

textarea.form-control {
    height: auto
}

.form-group {
    margin-bottom: 1rem
}

.form-text {
    display: block;
    margin-top: .25rem
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem
}

.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem
}

.form-check-input:disabled~.form-check-label,
.form-check-input[disabled]~.form-check-label {
    color: #6c757d
}

.form-check-label {
    margin-bottom: 0
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545
}

.is-invalid~.invalid-feedback {
    display: block
}

.form-control.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + .75rem);
    background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPScxMicgaGVpZ2h0PScxMicgZmlsbD0nbm9uZScgc3Ryb2tlPScjZGMzNTQ1Jz48Y2lyY2xlIGN4PSc2JyBjeT0nNicgcj0nNC41Jy8+PHBhdGggc3Ryb2tlLWxpbmVqb2luPSdyb3VuZCcgZD0nTTUuOCAzLjZoLjRMNiA2LjV6Jy8+PGNpcmNsZSBjeD0nNicgY3k9JzguMicgcj0nLjYnIGZpbGw9JyNkYzM1NDUnIHN0cm9rZT0nbm9uZScvPjwvc3ZnPg==);
    background-repeat: no-repeat;
    background-position: right calc(.375em + .1875rem) center;
    background-size: calc(.75em + .375rem) calc(.75em + .375rem)
}

.form-control.is-invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220, 53, 69, .25)
}

textarea.form-control.is-invalid {
    padding-right: calc(1.5em + .75rem);
    background-position: top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}

.form-check-input.is-invalid~.form-check-label {
    color: #dc3545
}

.form-check-input.is-invalid~.invalid-feedback {
    display: block
}

.btn {
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #212529;
    text-decoration: none
}

.btn.focus,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(41, 47, 59, .25)
}

.btn.disabled,
.btn:disabled {
    opacity: .65
}

.btn:not(:disabled):not(.disabled) {
    cursor: pointer
}

a.btn.disabled {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: #292f3b;
    border-color: #292f3b
}

.btn-primary.focus,
.btn-primary:focus,
.btn-primary:hover {
    color: #fff;
    background-color: #191d24;
    border-color: #14171d
}

.btn-primary.focus,
.btn-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(73, 78, 88, .5)
}

.btn-primary.disabled,
.btn-primary:disabled {
    color: #fff;
    background-color: #292f3b;
    border-color: #292f3b
}

.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #14171d;
    border-color: #0f1115
}

.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(73, 78, 88, .5)
}

.btn-secondary {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:hover {
    color: #fff;
    background-color: #5a6268;
    border-color: #545b62
}

.btn-secondary.focus,
.btn-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5)
}

.btn-secondary.disabled,
.btn-secondary:disabled {
    color: #fff;
    background-color: #6c757d;
    border-color: #6c757d
}

.btn-secondary:not(:disabled):not(.disabled).active,
.btn-secondary:not(:disabled):not(.disabled):active,
.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #545b62;
    border-color: #4e555b
}

.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(130, 138, 145, .5)
}

.btn-success {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-success.focus,
.btn-success:focus,
.btn-success:hover {
    color: #fff;
    background-color: #218838;
    border-color: #1e7e34
}

.btn-success.focus,
.btn-success:focus {
    box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5)
}

.btn-success.disabled,
.btn-success:disabled {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show>.btn-success.dropdown-toggle {
    color: #fff;
    background-color: #1e7e34;
    border-color: #1c7430
}

.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show>.btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(72, 180, 97, .5)
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger.focus,
.btn-danger:focus,
.btn-danger:hover {
    color: #fff;
    background-color: #c82333;
    border-color: #bd2130
}

.btn-danger.focus,
.btn-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5)
}

.btn-danger.disabled,
.btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-danger:not(:disabled):not(.disabled).active,
.btn-danger:not(:disabled):not(.disabled):active,
.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #bd2130;
    border-color: #b21f2d
}

.btn-danger:not(:disabled):not(.disabled).active:focus,
.btn-danger:not(:disabled):not(.disabled):active:focus,
.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(225, 83, 97, .5)
}

.btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-dark.focus,
.btn-dark:focus,
.btn-dark:hover {
    color: #fff;
    background-color: #23272b;
    border-color: #1d2124
}

.btn-dark.focus,
.btn-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5)
}

.btn-dark.disabled,
.btn-dark:disabled {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40
}

.btn-dark:not(:disabled):not(.disabled).active,
.btn-dark:not(:disabled):not(.disabled):active,
.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #1d2124;
    border-color: #171a1d
}

.btn-dark:not(:disabled):not(.disabled).active:focus,
.btn-dark:not(:disabled):not(.disabled):active:focus,
.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(82, 88, 93, .5)
}

.btn-link {
    font-weight: 400;
    color: #292f3b;
    text-decoration: none
}

.btn-link:hover {
    color: #0a0b0e
}

.btn-link.focus,
.btn-link:focus,
.btn-link:hover {
    text-decoration: underline
}

.btn-link.disabled,
.btn-link:disabled {
    color: #6c757d;
    pointer-events: none
}

.btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem
}

.btn-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .2rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block+.btn-block {
    margin-top: .5rem
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

.collapse:not(.show) {
    display: none
}

.dropdown {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle:after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty:after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.dropdown-menu-right {
    right: 0;
    left: auto
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #16181b;
    text-decoration: none;
    background-color: #e9ecef
}

.dropdown-item.active,
.dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #292f3b
}

.dropdown-item.disabled,
.dropdown-item:disabled {
    color: #adb5bd;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:focus,
.nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    color: #6c757d;
    pointer-events: none;
    cursor: default
}

.navbar {
    position: relative;
    padding: .5rem 1rem
}

.navbar,
.navbar .container,
.navbar .container-fluid {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus,
.navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem
}

.navbar-toggler:focus,
.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: 50%/100% 100% no-repeat
}

@media(max-width: 767.98px) {

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media(min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-md>.container,
    .navbar-expand-md>.container-fluid {
        flex-wrap: nowrap
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media(max-width: 991.98px) {

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media(min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-lg>.container,
    .navbar-expand-lg>.container-fluid {
        flex-wrap: nowrap
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

.navbar-light .navbar-brand,
.navbar-light .navbar-brand:focus,
.navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .active>.nav-link,
.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .show>.nav-link {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .5);
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSczMCcgaGVpZ2h0PSczMCc+PHBhdGggc3Ryb2tlPSdyZ2JhKDAsIDAsIDAsIDAuNSknIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLW1pdGVybGltaXQ9JzEwJyBzdHJva2Utd2lkdGg9JzInIGQ9J000IDdoMjJNNCAxNWgyMk00IDIzaDIyJy8+PC9zdmc+)
}

.navbar-dark .navbar-brand,
.navbar-dark .navbar-brand:focus,
.navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: hsla(0, 0%, 100%, .5)
}

.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
    color: hsla(0, 0%, 100%, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: hsla(0, 0%, 100%, .25)
}

.navbar-dark .navbar-nav .active>.nav-link,
.navbar-dark .navbar-nav .nav-link.active,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: hsla(0, 0%, 100%, .5);
    border-color: hsla(0, 0%, 100%, .1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url(data:image/svg+xml;charset=utf-8;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSczMCcgaGVpZ2h0PSczMCc+PHBhdGggc3Ryb2tlPSdyZ2JhKDI1NSwgMjU1LCAyNTUsIDAuNSknIHN0cm9rZS1saW5lY2FwPSdyb3VuZCcgc3Ryb2tlLW1pdGVybGltaXQ9JzEwJyBzdHJva2Utd2lkdGg9JzInIGQ9J000IDdoMjJNNCAxNWgyMk00IDIzaDIyJy8+PC9zdmc+)
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem
}

.card-title {
    margin-bottom: .75rem
}

.card-header,
.card-text:last-child {
    margin-bottom: 0
}

.card-header {
    padding: .75rem 1.25rem;
    background-color: rgba(0, 0, 0, .03);
    border-bottom: 1px solid rgba(0, 0, 0, .125)
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-img-top {
    flex-shrink: 0;
    width: 100%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-img-ads {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px);
    object-fit: cover;
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #e9ecef;
    border-radius: .25rem
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item:before {
    float: left;
    padding-right: .5rem;
    color: #6c757d;
    content: "/"
}

.breadcrumb-item+.breadcrumb-item:hover:before {
    text-decoration: underline;
    text-decoration: none
}

.breadcrumb-item.active {
    color: #6c757d
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #292f3b;
    background-color: #fff;
    border: 1px solid #dee2e6
}

.page-link:hover {
    z-index: 2;
    color: #0a0b0e;
    text-decoration: none;
    background-color: #e9ecef;
    border-color: #dee2e6
}

.page-link:focus {
    z-index: 3;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(41, 47, 59, .25)
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #292f3b;
    border-color: #292f3b
}

.page-item.disabled .page-link {
    color: #6c757d;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #dee2e6
}

.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media(prefers-reduced-motion:reduce) {
    .badge {
        transition: none
    }
}

a.badge:focus,
a.badge:hover {
    text-decoration: none
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.badge-warning {
    color: #212529;
    background-color: #ffc107
}

a.badge-warning:focus,
a.badge-warning:hover {
    color: #212529;
    background-color: #d39e00
}

a.badge-warning.focus,
a.badge-warning:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(255, 193, 7, .5)
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem
}

.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb
}

.alert-success hr {
    border-top-color: #b1dfbb
}

.alert-warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba
}

.alert-warning hr {
    border-top-color: #ffe8a1
}

.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb
}

.alert-danger hr {
    border-top-color: #f1b0b7
}

@-webkit-keyframes progress-bar-stripes {
    0% {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes {
    0% {
        background-position: 1rem 0
    }

    to {
        background-position: 0 0
    }
}

.media {
    display: flex;
    align-items: flex-start
}

.list-group-item-action {
    width: 100%;
    color: #495057;
    text-align: inherit
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none;
    background-color: #f8f9fa
}

.list-group-item-action:active {
    color: #212529;
    background-color: #e9ecef
}

.list-group-item {
    position: relative;
    display: block;
    padding: .75rem 1.25rem;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .125)
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled,
.list-group-item:disabled {
    color: #6c757d;
    pointer-events: none;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #292f3b;
    border-color: #292f3b
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px
}

.close {
    float: right;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    opacity: .5
}

.close:hover {
    color: #000;
    text-decoration: none
}

.close:not(:disabled):not(.disabled):focus,
.close:not(:disabled):not(.disabled):hover {
    opacity: .75
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0
}

a.close.disabled {
    pointer-events: none
}

@-webkit-keyframes spinner-border {
    to {
        transform: rotate(1turn)
    }
}

@keyframes spinner-border {
    to {
        transform: rotate(1turn)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid;
    border-right: .25em solid transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
}

@-webkit-keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

@media(prefers-reduced-motion:reduce) {
    .spinner-border {
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s
    }
}

.align-baseline {
    vertical-align: baseline !important
}

.bg-success {
    background-color: #28a745 !important
}

a.bg-success:focus,
a.bg-success:hover,
button.bg-success:focus,
button.bg-success:hover {
    background-color: #1e7e34 !important
}

.bg-info {
    background-color: #17a2b8 !important
}

a.bg-info:focus,
a.bg-info:hover,
button.bg-info:focus,
button.bg-info:hover {
    background-color: #117a8b !important
}

.bg-warning {
    background-color: #ffc107 !important
}

a.bg-warning:focus,
a.bg-warning:hover,
button.bg-warning:focus,
button.bg-warning:hover {
    background-color: #d39e00 !important
}

.bg-danger {
    background-color: #dc3545 !important
}

a.bg-danger:focus,
a.bg-danger:hover,
button.bg-danger:focus,
button.bg-danger:hover {
    background-color: #bd2130 !important
}

.bg-light {
    background-color: #f8f9fa !important
}

a.bg-light:focus,
a.bg-light:hover,
button.bg-light:focus,
button.bg-light:hover {
    background-color: #dae0e5 !important
}

.bg-dark {
    background-color: #343a40 !important
}

a.bg-dark:focus,
a.bg-dark:hover,
button.bg-dark:focus,
button.bg-dark:hover {
    background-color: #1d2124 !important
}

.bg-white {
    background-color: #fff !important
}

.bg-transparent {
    background-color: transparent !important
}

.border {
    border: 1px solid #dee2e6 !important
}

.border-bottom {
    border-bottom: 1px solid #dee2e6 !important
}

.border-0 {
    border: 0 !important
}

.rounded {
    border-radius: .25rem !important
}

.rounded-lg {
    border-radius: .3rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-flex {
    display: flex !important
}

@media(min-width: 768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-block {
        display: block !important
    }
}

@media(min-width: 992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-block {
        display: block !important
    }
}

.flex-column {
    flex-direction: column !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-center {
    align-items: center !important
}

.align-self-stretch {
    align-self: stretch !important
}

.overflow-hidden {
    overflow: hidden !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

@supports((position: -webkit-sticky) or (position:sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(0, 0, 0, .075) !important
}

.shadow {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.h-100 {
    height: 100% !important
}

.vh-100 {
    height: 100vh !important
}

.m-0 {
    margin: 0 !important
}

.mb-0 {
    margin-bottom: 0 !important
}

.ml-0 {
    margin-left: 0 !important
}

.mt-1 {
    margin-top: .25rem !important
}

.mr-1,
.mx-1 {
    margin-right: .25rem !important
}

.mb-1 {
    margin-bottom: .25rem !important
}

.ml-1,
.mx-1 {
    margin-left: .25rem !important
}

.mt-2,
.my-2 {
    margin-top: .5rem !important
}

.mr-2 {
    margin-right: .5rem !important
}

.mb-2,
.my-2 {
    margin-bottom: .5rem !important
}

.ml-2 {
    margin-left: .5rem !important
}

.mt-3,
.my-3 {
    margin-top: 1rem !important
}

.mb-3,
.my-3 {
    margin-bottom: 1rem !important
}

.ml-3 {
    margin-left: 1rem !important
}

.mt-4,
.my-4 {
    margin-top: 1.5rem !important
}

.mb-4,
.my-4 {
    margin-bottom: 1.5rem !important
}

.ml-4 {
    margin-left: 1.5rem !important
}

.mt-5 {
    margin-top: 3rem !important
}

.mb-5 {
    margin-bottom: 3rem !important
}

.p-0 {
    padding: 0 !important
}

.pt-0,
.py-0 {
    padding-top: 0 !important
}

.pr-0,
.px-0 {
    padding-right: 0 !important
}

.pb-0,
.py-0 {
    padding-bottom: 0 !important
}

.px-0 {
    padding-left: 0 !important
}

.py-1 {
    padding-top: .25rem !important
}

.px-1 {
    padding-right: .25rem !important
}

.pb-1,
.py-1 {
    padding-bottom: .25rem !important
}

.px-1 {
    padding-left: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.py-2 {
    padding-top: .5rem !important
}

.px-2 {
    padding-right: .5rem !important
}

.pb-2,
.py-2 {
    padding-bottom: .5rem !important
}

.px-2 {
    padding-left: .5rem !important
}

.p-3 {
    padding: 1rem !important
}

.pt-3,
.py-3 {
    padding-top: 1rem !important
}

.pb-3,
.py-3 {
    padding-bottom: 1rem !important
}

.py-4 {
    padding-top: 1.5rem !important
}

.px-4 {
    padding-right: 1.5rem !important
}

.pb-4,
.py-4 {
    padding-bottom: 1.5rem !important
}

.px-4 {
    padding-left: 1.5rem !important
}

.pt-5,
.py-5 {
    padding-top: 3rem !important
}

.pb-5,
.py-5 {
    padding-bottom: 3rem !important
}

.mx-n2 {
    margin-right: -.5rem !important
}

.mb-n2 {
    margin-bottom: -.5rem !important
}

.mx-n2 {
    margin-left: -.5rem !important
}

.mx-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important
}

.mt-auto {
    margin-top: auto !important
}

.mr-auto,
.mx-auto {
    margin-right: auto !important
}

.ml-auto,
.mx-auto {
    margin-left: auto !important
}

@media(min-width: 576px) {
    .mx-sm-0 {
        margin-right: 0 !important;
        margin-left: 0 !important
    }

    .px-sm-2 {
        padding-right: .5rem !important;
        padding-left: .5rem !important
    }

    .px-sm-3 {
        padding-right: 1rem !important;
        padding-left: 1rem !important
    }
}

@media(min-width: 992px) {
    .mb-lg-0 {
        margin-bottom: 0 !important
    }

    .mt-lg-4 {
        margin-top: 1.5rem !important
    }

    .mt-lg-5 {
        margin-top: 3rem !important
    }

    .px-lg-5 {
        padding-right: 3rem !important;
        padding-left: 3rem !important
    }
}

.stretched-link:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: transparent
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media(min-width: 768px) {
    .text-md-right {
        text-align: right !important
    }
}

@media(min-width: 992px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }
}

.text-uppercase {
    text-transform: uppercase !important
}

.font-weight-bold {
    font-weight: 700 !important
}

.font-weight-bolder {
    font-weight: bolder !important
}

.text-white {
    color: #fff !important
}

.text-success {
    color: #28a745 !important
}

a.text-success:focus,
a.text-success:hover {
    color: #19692c !important
}

.text-danger {
    color: #dc3545 !important
}

a.text-danger:focus,
a.text-danger:hover {
    color: #a71d2a !important
}

.text-light {
    color: #f8f9fa !important
}

a.text-light:focus,
a.text-light:hover {
    color: #cbd3da !important
}

.text-muted {
    color: #6c757d !important
}

.visible {
    visibility: visible !important
}

@media print {

    *,
    :after,
    :before {
        text-shadow: none !important;
        box-shadow: none !important
    }

    a:not(.btn) {
        text-decoration: underline
    }

    thead {
        display: table-header-group
    }

    img,
    tr {
        page-break-inside: avoid
    }

    h2,
    h3,
    p {
        orphans: 3;
        widows: 3
    }

    h2,
    h3 {
        page-break-after: avoid
    }

    @page {
        size: a3
    }

    .container,
    body {
        min-width: 992px !important
    }

    .navbar {
        display: none
    }

    .badge {
        border: 1px solid #000
    }

    .table {
        border-collapse: collapse !important
    }

    .table td,
    .table th {
        background-color: #fff !important
    }
}

.gad-bot {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 51px;
    background: rgba(0, 0, 0, .5);
    border-top: 1px solid #292f3b;
    z-index: 99999999999999;
    transition: transform .3s;
    transform: translate3d(0, 160%, 0)
}

.gad-bot,
.gad-bot .gad-bclose {
    display: flex;
    align-items: center;
    justify-content: center
}

.gad-bot .gad-bclose {
    position: absolute;
    top: -25px;
    right: 7px;
    width: 35px;
    height: 25px;
    background: #292f3b;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    color: #fff;
    cursor: pointer
}

.gad-bot .gad-bclose .svg {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke: currentColor;
    fill: currentColor;
    stroke-width: 0
}

@media(min-width: 576px) {
    .gad-bot {
        display: none
    }
}

#email-popup {
    position: fixed;
    transform: translateY(-50%);
    top: 50%;
    right: 10px;
    left: 10px;
    width: calc(100% - 20px);
    z-index: 1;
    background-color: #28a745;
    color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, .5);
    padding: 15px;
    text-align: center;
    transition: .5s;
    -webkit-animation: slideUp 1s ease-out 0s 1;
    animation: slideUp 1s ease-out 0s 1;
    border-radius: 3px
}

@media(min-width: 992px) {
    #email-popup {
        width: 360px;
        left: unset
    }
}

#email-popup h1 {
    font-size: 16px;
    color: #fff
}

#email-popup h2 {
    font-size: 12px;
    color: #fff;
    font-weight: 300;
    margin-bottom: 15px
}

#email-popup [type=submit],
#email-popup input[name=email] {
    width: 100%;
    height: 36px;
    text-align: center;
    font-size: 14px;
    border: 0
}

#email-popup [type=submit] {
    margin-top: 5px;
    font-weight: 500;
    background-color: #222;
    color: #fff
}

#email-popup [type=submit] .spinner-border {
    display: none
}

#email-popup [type=submit].loading .spinner-border {
    display: inherit
}

#email-popup [type=submit].loading .word {
    display: none
}

#email-popup .no-thanks {
    color: #fff;
    font-size: 12px;
    margin-top: 15px;
    font-weight: 500;
    cursor: pointer
}

#email-popup .no-thanks:hover {
    text-decoration: underline
}

@-webkit-keyframes slideUp {
    0% {
        transform: translateY(100%)
    }

    to {
        transform: translateY(-50%)
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(100%)
    }

    to {
        transform: translateY(-50%)
    }
}

.bg-notification {
    position: fixed;
    bottom: 10px;
    right: 10px;
    left: 10px;
    z-index: 10;
    width: calc(100% - 20px);
    transform: translateY(100%);
    -webkit-animation: showNotification 3s;
    animation: showNotification 3s;
    transition: .5s
}

@media(min-width: 992px) {
    .bg-notification {
        width: 360px;
        left: unset
    }
}

.bg-notification .notification {
    padding: 15px;
    border-radius: 3px;
    text-align: center;
    font-weight: 400;
    font-size: 14px;
    margin: auto
}

.bg-notification .notification.success {
    background-color: #41ad49;
    color: #fff
}

.bg-notification .notification.error {
    background-color: #e23a3a;
    color: #fff
}

@-webkit-keyframes showNotification {
    0% {
        transform: translateY(100%)
    }

    15% {
        transform: translateY(0)
    }

    85% {
        transform: translateY(0)
    }

    to {
        transform: translateY(100%)
    }
}

@keyframes showNotification {
    0% {
        transform: translateY(100%)
    }

    15% {
        transform: translateY(0)
    }

    85% {
        transform: translateY(0)
    }

    to {
        transform: translateY(100%)
    }
}

#mercadopago-bg {
    opacity: 1;
    text-align: center;
    z-index: 999998;
    overflow: auto;
    margin: 0;
    padding: 0;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .9);
    top: 0;
    left: 0;
    -webkit-overflow-scrolling: touch;
    transform: translateZ(0);
    display: flex;
    justify-content: center
}

#mercadopago-box {
    width: 100%;
    margin: 0 20px 20px;
    padding-top: 50px
}

@media(min-width: 576px) {
    #mercadopago-box {
        max-width: 400px
    }
}

#mercadopago-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: right;
    font-size: 14px
}

#mercadopago-header .cancel {
    color: #a9a9a9;
    margin-right: 20px
}

#mercadopago-form form input {
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 18px;
    padding: 15px;
    border-radius: 6px;
    width: 100%;
    border: 3px solid #fff;
    margin-bottom: 10px;
    outline: 0;
    font-family: Raleway, sans-serif
}

#mercadopago-form form input:focus {
    border: 3px solid green
}

#mercadopago-form form button {
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 18px;
    padding: 20px 15px;
    border-radius: 6px;
    border: 0;
    background-color: green;
    width: 100%;
    color: #fff;
    font-family: Raleway, sans-serif;
    margin-bottom: 20px
}

#mercadopago-form form .title {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 400;
    font-size: 28px;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
    font-family: Raleway, sans-serif
}

#mercadopago-form form .amount {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #fff;
    margin: 0 0 40px;
    font-size: 16px
}

#mercadopago-form form .amount .green {
    color: #32cd32
}

#mercadopago-form form .message {
    text-align: center;
    color: #fff;
    margin: 20px 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.25em
}

#mercadopago-pix .title {
    font-size: 28px
}

#mercadopago-pix .copia-e-cola,
#mercadopago-pix .title {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-weight: 400;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
    font-family: Raleway, sans-serif
}

#mercadopago-pix .copia-e-cola {
    font-size: 16px;
    margin-top: 10px
}

#mercadopago-pix input {
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 18px;
    padding: 15px;
    border-radius: 6px;
    width: 100%;
    border: 3px solid #fff;
    margin-bottom: 10px;
    outline: 0;
    font-family: Raleway, sans-serif
}

#mercadopago-pix input:focus {
    border: 3px solid green
}

#mercadopago-pix button {
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 18px;
    padding: 20px 15px;
    border-radius: 6px;
    border: 0;
    background-color: green;
    width: 100%;
    color: #fff;
    font-family: Raleway, sans-serif;
    margin-bottom: 20px
}

#mercadopago-pix .pix-image {
    width: 100%
}

.search-form {
    position: relative
}

.search-form .search-input {
    width: 100%;
    padding: 15px 30px 15px 60px;
    background: hsla(0, 0%, 100%, .2);
    border: none;
    outline: none;
    color: hsla(0, 0%, 100%, .7);
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .03);
    transition: background .4s, box-shadow .2s;
    font-weight: 500
}

.search-form .search-input:active,
.search-form .search-input:focus {
    box-shadow: 0 10px 20px rgba(0, 0, 0, .15);
    background: #fff;
    color: #292f3b
}

.search-form .search-input:active+.search-icon:after,
.search-form .search-input:active+.search-icon:before,
.search-form .search-input:focus+.search-icon:after,
.search-form .search-input:focus+.search-icon:before {
    border-color: #28a745
}

.search-form .search-icon {
    width: 22px;
    height: 22px;
    border: none;
    background: 0 0;
    position: absolute;
    color: transparent;
    top: 50%;
    left: 19px;
    margin-top: -10px;
    outline: none;
    cursor: pointer;
    transform: scale(.85);
    transition: transform .1s linear;
    font-size: 10px
}

.search-form .search-icon:before {
    content: " ";
    border: 2px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px
}

.search-form .search-icon:after {
    content: " ";
    border-top: 2px solid #fff;
    position: absolute;
    bottom: 8px;
    right: 8px;
    width: 12px;
    height: 1px;
    transition: width .1s linear;
    transform-origin: 100% 50%;
    transform: rotate(-135deg)
}

.section-blog .blog-title {
    font-weight: 700
}

.section-blog .search-result {
    font-weight: 700;
    font-size: 18px
}

.section-blog .search-result__text {
    color: #999
}

.post-box {
    position: relative;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 30px;
    text-decoration: none;
    overflow: hidden;
    width: 100%;
    outline: none;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .03);
    transition: border .15s linear, transform .15s linear, background-color .15s linear, box-shadow .15s linear, opacity .15s linear
}

.post-box:hover {
    border: 1px solid rgba(136, 149, 162, .2);
    background-color: #fcfcfc;
    text-decoration: none
}

.post-box__image img {
    border-radius: 3px;
    max-width: 100%;
    height: auto
}

.post-box__right {
    display: flex;
    flex-direction: column
}

.post-box__title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px
}

@media(max-width: 575.98px) {
    .post-box__title {
        margin-top: .5rem
    }
}

@media(min-width: 768px) {
    .post-box__title {
        font-size: 24px;
        line-height: 28px
    }
}

@media(min-width: 1200px) {
    .post-box__title {
        font-size: 28px;
        line-height: 32px
    }
}

.post-box__title a {
    text-decoration: none
}

.post-box__description {
    font-size: 16px;
    line-height: 22px;
    color: #666
}

.avatar {
    display: flex;
    margin-top: auto
}

.avatar__image {
    width: 32px;
    height: 32px
}

.avatar__image img {
    max-width: 100%;
    border-radius: 50%
}

.avatar__infos {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-left: 5px
}

.avatar__infos span {
    display: block;
    font-size: 13px;
    line-height: 1;
    color: #999
}

.sep-1 {
    margin: 0
}

.sep-1,
.sep-2 {
    background: #28a745
}

.sep-2 {
    height: 2px;
    margin-top: 2px
}

.share-button {
    border-radius: 50%;
    border: 0;
    height: 32px;
    width: 32px;
    line-height: 1
}

.share-button svg {
    line-height: 1;
    width: 22px;
    height: 22px;
    fill: #fff
}

.share-dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: none;
    width: 95%;
    max-width: 500px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, .15);
    z-index: -1;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 4px;
    background-color: #fff
}

.share-dialog.is-open {
    display: block;
    z-index: 2
}

.share-dialog .button,
.share-dialog button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #777;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.1;
    text-transform: capitalize;
    text-decoration: none;
    white-space: nowrap;
    border-radius: 4px;
    border: 1px solid #ddd;
    cursor: pointer
}

.share-dialog .button:hover,
.share-dialog button:hover {
    border-color: #cdd
}

.share-dialog .copy-link {
    padding-left: 30px;
    padding-right: 30px
}

.share-dialog header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px
}

.share-dialog .targets {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 20px
}

.share-dialog .close-button {
    background-color: transparent;
    border: none;
    padding: 0
}

.share-dialog .close-button svg {
    margin-right: 0
}

.share-dialog .link {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    background-color: #eee
}

.share-dialog .pen-url {
    margin-right: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.share-dialog svg {
    width: 20px;
    height: 20px;
    margin-right: 7px
}

.btn-menu {
    border: 0;
    background-color: transparent;
    fill: #fff
}

.nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 270px;
    transform: translateX(-100%);
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #f6f6f6;
    padding: 15px;
    z-index: 1030;
    overflow-y: auto;
    transition: transform .5s cubic-bezier(0, 0, .3, 1)
}

.nav-menu__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    line-height: 40px
}

.nav-menu__header__name {
    font-weight: 700
}

.nav-menu__header__close-btn {
    border: 0;
    background-color: transparent;
    padding: 0;
    margin: 0
}

.nav-menu__header__close-btn svg {
    width: 20px;
    height: 20px
}

.nav-menu__header__close-btn:hover {
    fill: #8b0000
}

.nav-menu__list {
    padding: 0;
    margin: 0;
    list-style: none
}

.nav-menu__footer {
    margin-top: auto
}

.nav-menu__footer-list {
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 12px
}

.nav-menu__link {
    display: block;
    position: relative;
    -webkit-font-smoothing: antialiased;
    height: 40px;
    line-height: 40px
}

.nav-menu-bg {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    background-color: #000;
    visibility: hidden;
    z-index: 1025;
    transition: visibility .5s linear, opacity .5s linear
}

.nav-menu--opened .nav-menu {
    transform: translateX(0);
    transition: transform .5s linear
}

.nav-menu--opened .nav-menu-bg {
    visibility: visible;
    transition: opacity .5s linear;
    opacity: .7
}

html {
    position: relative;
    min-height: 100%
}

body {
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    background: #f6f6f6;
    color: #292f3b;
    margin-bottom: 120px
}

@media(min-width: 992px) {
    body {
        margin-bottom: 90px
    }
}

body.error-page {
    margin-bottom: 0
}

h1 {
    font-size: 2rem
}

h2 {
    font-size: 1.7rem
}

h3 {
    font-size: 1.4rem
}

.logo {
    max-height: 42px
}

.font-weight-bold {
    font-weight: 600 !important
}

.font-weight-bolder {
    font-weight: 700 !important
}

.bg-gray {
    background-color: #e9ecef
}

.bg-gwa,
.btn-gwa {
    background-color: #00ACC1
}

.btn-gwa,
.btn-gwa:hover {
    color: #fff
}

.btn-send-group {
    background-color: #fff;
    color: #292f3b
}

.btn-send-group:hover {
    background-color: #28a745;
    color: #fff
}

.btn-view-groups {
    color: #fff;
    background-color: unset;
    border: 1px solid #fff
}

.btn-view-groups:hover {
    color: #fff;
    background-color: #28a745;
    border: 1px solid #28a745
}

.btn-header {
    font-size: 85%;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding: 10px 0
}

@media(min-width: 349px) and (max-width:374px) {
    .btn-header {
        font-size: 12px
    }
}

@media(max-width: 348px) {
    .btn-view-groups {
        display: none
    }
}

.btn-xs {
    padding: .25rem .5rem;
    font-size: .675rem;
    line-height: 1.5;
    border-radius: .2rem
}

.separator {
    width: 100px;
    height: 2px;
    background-color: rgba(0, 0, 0, .1);
    border: 0;
    margin-top: 0
}

.categories .row-categories {
    max-height: 100px;
    overflow: hidden;
    transition: all .5s linear
}

.categories .row-categories-open {
    max-height: 1000px;
    transition: all .5s linear
}

.categories .col-category {
    width: 25%;
    padding-bottom: 10px
}

@media(min-width: 768px) {
    .categories .col-category {
        width: 16.6666666667%
    }
}

@media(min-width: 992px) {
    .categories .col-category {
        width: 12.5%
    }
}

@media(min-width: 1200px) {
    .categories .col-category {
        width: 10%
    }
}

.categories .category {
    text-align: center;
    text-decoration: none;
    padding: 13px 0;
    display: block
}

.categories .category .category-logo {
    display: block;
    height: 45px;
    line-height: 100%;
    font-size: 35px;
    color: #292f3b;
    transition: all .2s linear
}

.categories .category .category-logo svg {
    display: inline-block;
    width: .8em;
    height: .8em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor
}

.categories .category .category-name {
    display: block;
    font-size: 13px;
    font-weight: 600;
    color: #292f3b;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 0
}

.categories .category.active .category-logo,
.categories .category:hover .category-logo {
    color: #28a745
}

.categories .box-more-categories {
    text-align: center;
    position: absolute;
    width: 100%;
    margin-top: -15px;
    margin-left: -15px;
    margin-right: -15px;
    z-index: 2
}

.section-title {
    text-align: center;
    margin-bottom: 1rem
}

.section-description {
    text-align: center;
    font-size: 1rem
}

.group .card-img-top {
    aspect-ratio: 2
}

.group .card-title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.group .card-text {
    height: 96px;
    overflow: hidden
}

.group .card-category {
    padding: .25rem .5rem;
    font-size: .7rem;
    line-height: 1.5;
    border-radius: .2rem;
    text-transform: uppercase;
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
    position: absolute;
    margin-top: -32px;
    font-weight: 600
}

.group .btn-vip .text {
    font-size: 10px;
    line-height: 1
}

.group .btn-vip .spinner {
    display: none
}

.group.vip {
    border: 2px solid #000;
    border-radius: calc(.25rem - 1px)
}

.group.vip .vip-star {
    position: absolute;
    right: -10px;
    top: -10px;
    background: #000;
    border: 2px solid #000;
    border-radius: 100%;
    padding: 5px
}

.group .edit-options {
    position: absolute;
    top: 10px;
    right: 10px;
    border: 2px solid #fff
}

.group .options {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: hsla(0, 0%, 100%, .95);
    display: none;
    flex-direction: column
}

.group .options.show {
    display: flex
}

.group .options .edit {
    flex: 1;
    border-bottom: 1px solid #ccc;
    display: flex;
    align-items: center;
    justify-content: center
}

.group .options .actions {
    padding: 10px;
    display: flex
}

.more-infos {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    padding: 15px
}

.more-infos .category {
    padding: .25rem .5rem;
    font-size: .7rem;
    border-radius: .2rem;
    text-transform: uppercase;
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
    font-weight: 600;
    display: inline-block
}

.more-infos .hits {
    margin-top: 15px;
    margin-bottom: 10px;
    font-size: 14px
}

.more-infos .hits a {
    color: #6495ed
}

.more-infos .rules small {
    font-size: 11px;
    line-height: 120%;
    display: block;
    color: grey;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 14px
}

.more-infos .rules ol {
    list-style-position: inside;
    margin-top: 10px;
    counter-reset: item;
    padding: 0 0 12px;
    border-bottom: 1px solid #e6e6e6;
    margin-bottom: 10px
}

.more-infos .rules ol li {
    list-style-type: none;
    counter-increment: item;
    font-size: 14px;
    word-break: break-word
}

.more-infos .rules ol li:before {
    font-weight: 700;
    content: counter(item);
    padding-right: .5em
}

.more-infos .last-check {
    font-size: 12px
}

.additional-text {
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem;
    padding: 15px;
    background-color: #fff
}

.additional-text p:last-child {
    margin-bottom: 0
}

.pagination {
    justify-content: center
}

.pagination .page-item.active .page-link {
    color: #fff;
    background-color: #28a745;
    border-color: #28a745
}

.pagination .page-item .page-link {
    color: #094824
}

.section-ghosts .list-ghosts {
    list-style: none;
    padding: 0
}

@media(min-width: 992px) {
    .section-ghosts .list-ghosts {
        -moz-column-count: 3;
        column-count: 3
    }
}

.section-ghosts .list-ghosts .list-group-item {
    text-align: center;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    height: calc(100% - 15px);
    justify-content: center
}

.section-posts .list-group-item {
    margin-bottom: 15px;
    height: calc(100% - 15px)
}

.section-posts .post-box-2 {
    display: flex;
    height: calc(100% - 15px);
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 30px;
    text-decoration: none;
    overflow: hidden;
    width: 100%;
    outline: none;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .03);
    transition: border .15s linear, transform .15s linear, background-color .15s linear, box-shadow .15s linear, opacity .15s linear
}

.section-posts .post-box-2:hover {
    border: 1px solid rgba(136, 149, 162, .2);
    background-color: #fcfcfc;
    text-decoration: none
}

.row-adapter {
    margin-left: -7.5px;
    margin-right: -7.5px
}

.row-adapter [class|=col] {
    padding-left: 7.5px;
    padding-right: 7.5px
}

.section-post .post-title {
    font-weight: 700;
    text-align: center
}

@media(min-width: 992px) {
    .section-post .post-title {
        font-size: 42px
    }
}

.section-post .post-description {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: #555
}

@media(min-width: 992px) {
    .section-post .post-description {
        font-size: 20px;
        line-height: 30px
    }
}

.section-post .post {
    padding: 20px;
    font-size: 18px;
    line-height: 27px;
    border-radius: 4px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .03)
}

@media(min-width: 992px) {
    .section-post .post {
        padding: 30px;
        font-size: 20px;
        line-height: 30px
    }
}

.section-post .post img {
    max-width: 100%;
    height: auto
}

.section-post .post>p:last-child {
    margin-bottom: 0
}

.section-post .post a {
    color: #06c;
    text-decoration: underline
}

.section-post .post a:hover {
    text-decoration: none
}

.section-post .post h2,
.section-post .post p {
    margin-bottom: 1.5rem
}

.section-faq h2 {
    text-align: left;
    padding-bottom: 15px
}

.section-faq h2+hr {
    width: 100px;
    height: 1px;
    background-color: rgba(0, 0, 0, .1);
    border: 0;
    margin-top: 0;
    margin-left: 0
}

.section-faq p {
    font-size: 16px;
    font-weight: 300
}

@media(min-width: 992px) {
    .section-faq p {
        font-size: 20px
    }
}

.section-faq ol {
    font-size: 16px;
    font-weight: 300;
    padding: 0;
    list-style-position: inside
}

@media(min-width: 992px) {
    .section-faq ol {
        font-size: 20px
    }
}

.section-faq ol+h2 {
    margin-top: 30px
}

footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #292f3b;
    font-size: 14px;
    color: #fff
}

footer .link {
    text-transform: uppercase;
    color: #fff;
    font-weight: 500;
    transition: all .2s
}

footer .link:hover {
    color: #28a745;
    text-decoration: none
}

.box {
    background-color: #fff;
    padding: 1.25rem;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: .25rem
}

.section-send-group #prevImage {
    display: block;
    background-size: cover;
    background-position: 50%;
    background-color: #dfdfdf
}

.section-send-group #prevImage:before {
    content: "";
    float: left;
    padding-bottom: 50%
}

.section-send-group #prevImage:after {
    content: "";
    display: table;
    clear: both
}

#create-group.first-step .form-group,
#create-group .sendLink {
    display: none
}

#create-group.first-step .groupLink,
#create-group.first-step .sendLink {
    display: block
}

#create-group #prevImageMobile {
    display: block;
    background-size: cover;
    background-position: 50%;
    background-color: #dfdfdf;
    border-radius: 5px;
    margin-bottom: 15px
}

#create-group #prevImageMobile:before {
    content: "";
    float: left;
    padding-bottom: 50%
}

#create-group #prevImageMobile:after {
    content: "";
    display: table;
    clear: both
}

#create-group #groupImageUrl {
    display: none
}

#create-group #groupImage {
    z-index: -1;
    margin-top: -56px;
    position: absolute;
    width: auto;
    visibility: hidden
}

#create-group .sfImage {
    border-radius: 4px;
    padding: 30px;
    border: 1px solid #dfdfdf;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

#create-group .rules {
    border: 1px solid #ced4da;
    border-radius: .3rem;
    padding: 10px 15px
}

#create-group .rules .rule {
    position: relative
}

#create-group .rules .rule label {
    margin-bottom: 0;
    margin-left: 10px
}

#create-group .words {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    z-index: 1030;
    font-size: 80%;
    padding: 10px 0;
    background-color: red;
    color: #fff
}

#create-group .words strong {
    background-color: #000
}

.vip-message {
    border-radius: 5px;
    padding: 30px;
    text-align: center;
    border: 2px solid #eca919;
    margin-bottom: 30px;
    background: linear-gradient(#f3bf51, #eca919)
}

.vip-message .vip-star {
    display: inline-block;
    background: #000;
    border: 2px solid #000;
    border-radius: 100%;
    padding: 5px
}

.vip-message .vip-star img {
    width: 40px
}

.empty-message {
    padding: 30px;
    text-align: center;
    border: 2px dashed #ddd;
    margin: 0 15px 30px
}

.svg-sm {
    width: 30px;
    height: 30px
}

.svg-green {
    fill: #28a745
}

.border-green {
    border-color: #28a745 !important
}

.font-sm {
    font-size: 80%
}

.return-message {
    position: relative
}

.return-message .close {
    position: absolute;
    top: 5px;
    right: 10px
}

.return-message .close:focus {
    outline: none
}

.return-message .close:first-child {
    display: none
}

.page-link:focus {
    box-shadow: none
}

.invalid-feedback {
    display: block
}

.invalid-feedback ul {
    padding: 0;
    list-style: none;
    font-weight: 500
}

.error-page .icon {
    font-size: 100px;
    line-height: 1
}

.error-page .code {
    font-size: 4rem;
    font-weight: 700
}

.error-page .separator {
    background-color: #28a745;
    height: 5px;
    width: 50px;
    border-radius: 10px
}

.overlay {
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .8);
    position: fixed;
    height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px
}

.section-text h4 {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600
}